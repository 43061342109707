@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap"); */

@font-face {
  font-family: "BCarabic";
  src: url("./fonts/BCarabic.ttf");
}

body {
  font-family: "BCarabic" !important;
  cursor: default;
  background: #faf8f9;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "BCarabic";
  font-optical-sizing: auto;
  font-weight: 700;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 70px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 3px;
  background: radial-gradient(farthest-side, #8f6398 95%, #0000) 50% 0/12px 12px
      no-repeat,
    radial-gradient(
        farthest-side,
        #0000 calc(100% - 5px),
        #8f6398 calc(100% - 4px)
      )
      content-box;
  animation: l6 2s infinite;
}
@keyframes l6 {
  to {
    transform: rotate(1turn);
  }
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

html {
  overflow: auto !important;
  padding-right: 0 !important;
}

.img {
  background-image: url("../src/Svg/qiranbook-background.svg");
  background-repeat: repeat;
  background-position: center;
  background-size: contain;
}

@media (max-width: 760px) {
  .img {
    background-image: url("../src/Svg/qiranbook-background.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
/* .img-2 {
  background-image: url("../src/Svg/qiranbook-background.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
} */

.hero {
  background-image: url("/src/images/new hero.svg");
  background-repeat: repeat;
  background-size: contain;
  background-position: center;
}

.navSearch a.active {
  background-color: #d86a82;
  color: white;
  transition: all 0.3s;
}

a.active {
  color: #d86a82;
  font-weight: bolder;
}

a.active.active-male {
  color: #d86a82;
}
a.active.active-female {
  color: #8f6398;
}

.my_shadow {
  box-shadow: 0px 0px 15px -5px rgb(0 0 0 / 0.1);
}
.nav_search a.active {
  background-color: #8f6398;
}
.cursor-pointer,
a,
img {
  user-select: none;
}

.chat-background {
  background-image: url("../src/images/qiranbook-background.svg");
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
}

.border_chat-ar {
  border: 8px solid rgb(239 68 68 / var(--tw-bg-opacity));
  border-color: #8f6398 #e2e8f0 #e2e8f0 #8f6398;
}

.border_chat-en {
  border: 8px solid rgb(239 68 68 / var(--tw-bg-opacity));
  border-color: #8f6398 #8f6398 #e2e8f0 #e2e8f0;
}

.border_chat2-ar {
  border: 8px solid rgb(239 68 68 / var(--tw-bg-opacity));
  border-color: rgb(148 163 184 / var(--tw-bg-opacity))
    rgb(148 163 184 / var(--tw-bg-opacity))
    rgb(226 232 240 / var(--tw-bg-opacity))
    rgb(226 232 240 / var(--tw-bg-opacity));
}

.border_chat2-en {
  border: 8px solid rgb(239 68 68 / var(--tw-bg-opacity));
  border-color: rgb(148 163 184 / var(--tw-bg-opacity))
    rgb(226 232 240 / var(--tw-bg-opacity))
    rgb(226 232 240 / var(--tw-bg-opacity))
    rgb(148 163 184 / var(--tw-bg-opacity));
}
.footer__links {
  font-weight: 400;
  color: #666;
  font-size: 18px;
  line-height: 1.72;
  text-align: left;
  box-sizing: border-box;
  background: transparent;
  border: 0;
  margin: 0;
  vertical-align: baseline;
  padding-left: 15px;
  position: relative;
  width: 100%;
  padding-bottom: 14px;
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 30px;
  padding-right: 40px;
}
.footer__links a {
  position: relative;
}
.footer__links a::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.my-component {
  transition: transform 0.3s;
}

.my-component:hover {
  transform: scale(1.1);
}

.link-title-ar:hover > * {
  transform: translateX(-5px);
  transition: all 0.3s;
}
.link-title-en:hover > * {
  transform: translateX(5px);
  transition: all 0.3s;
}

@layer utilities {
  .text-gradient {
    background: linear-gradient(45deg, #8f6398, #d86a82);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
}
